import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import anime from 'animejs';

const Container = styled.div`
  position: relative;
  height: 100vh;
  background: #121212;`;

const VideoBackground = styled.video`
  position: fixed; /* Cover the entire viewport */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 30px;
  height: 100%;
  background: rgba(18, 18, 18, 0.5);
`;

const Title = styled.h1`
  font-size: 4em;
  color: #F8F8F8;
  margin-bottom: 0;
  font-weight: 700;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  font-family: 'Noto Sans', sans-serif;
  @media (max-width: 768px) {
    font-size: 3em;
  }

  span {
    &.highlight {
      color: #A67DF3;
    }
  }
`;

const Subtitle = styled.h2`
  font-size: 2em;
  color: #F8F8F8;
  opacity: 0;
  font-weight: 200; 
  font-family: 'Noto Sans', sans-serif;
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

export function App() {
  const titleRef = useRef();
  const subtitleRef = useRef();
  const videoRef = useRef(undefined);

  useEffect(() => {
    videoRef.current.defaultMuted = true;
  })

  useEffect(() => {
    const titleNode = titleRef.current;
    const subtitleNode = subtitleRef.current;
    const chars = titleNode.textContent.split('');
    titleNode.textContent = '';
    chars.forEach((char, i) => {
      const span = document.createElement('span');
      span.textContent = char;
      span.style.opacity = '0';
      span.className = `letter letter-${i}`;
      if (i > 5) {
        span.className += ' highlight'; // Add 'highlight' class to specific letters
      }
      titleNode.appendChild(span);
    });

    const timeline = anime.timeline({
      targets: '.letter',
      easing: 'easeInOutQuad',
      duration: 2250,
      delay: anime.stagger(150)
    });

    timeline
      .add({
        targets: '.letter',
        opacity: [0, 1]
      })
      .add({
        targets: '.letter',
        opacity: 1,
        duration: 1000,
        easing: 'easeOutExpo',
        delay: 0,
        complete: () => {
          if (subtitleNode) {
            anime({
              targets: subtitleNode,
              opacity: [0, 1],
              easing: 'easeInOutQuad',
              duration: 500
            });
          }
        }
      });
  }, []);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const videoSrc = isMobile
    ? 'https://storage.googleapis.com/bld-develop-video-bucket/b51f8c4e-0b46-4b8f-bfd6-2e9d5f9e1a6d-mobile.mp4'
    : 'https://storage.googleapis.com/bld-develop-video-bucket/b51f8c4e-0b46-4b8f-bfd6-2e9d5f9e1a6d.mp4';

  return (
    <Container>
      <VideoBackground
        ref={videoRef}
        loop
        autoPlay
        muted
        playsInline
        poster="https://i.vimeocdn.com/video/1164337020-07555fad6600082b6ca4de70cec94fa6d746471c08054b153217e838b60efb79-d?mw=1800&mh=949">
        <source src={videoSrc} type="video/mp4" />
      </VideoBackground>

      <Content>
        <Title ref={titleRef}>mindfu<span>loop</span></Title>
        <Subtitle className="subtitle" ref={subtitleRef}>coming soon</Subtitle>
      </Content>
    </Container>
  );
}
